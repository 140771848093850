// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfcustomfeature from "../../blocks/cfcustomfeature/src/Cfcustomfeature";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import ThemeBlock from "../../blocks/themeblock/src/ThemeBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Cfcustomfeature82 from "../../blocks/cfcustomfeature82/src/Cfcustomfeature82";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Applepayintegration2 from "../../blocks/applepayintegration2/src/Applepayintegration2";
import Cfcustomfeature92 from "../../blocks/cfcustomfeature92/src/Cfcustomfeature92";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Pricingengine2 from "../../blocks/pricingengine2/src/Pricingengine2";
import Cfcustomfeature122 from "../../blocks/cfcustomfeature122/src/Cfcustomfeature122";
import Googleadsenseintegration from "../../blocks/googleadsenseintegration/src/Googleadsenseintegration";
import Cfcustomfeature43 from "../../blocks/cfcustomfeature43/src/Cfcustomfeature43";
import Cfcustomfeature112 from "../../blocks/cfcustomfeature112/src/Cfcustomfeature112";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Itemavailability from "../../blocks/itemavailability/src/Itemavailability";
import Savedcards from "../../blocks/savedcards/src/Savedcards";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfcustomfeature72 from "../../blocks/cfcustomfeature72/src/Cfcustomfeature72";
import Storelocator2 from "../../blocks/storelocator2/src/Storelocator2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Cfcustomfeature102 from "../../blocks/cfcustomfeature102/src/Cfcustomfeature102";
import Location from "../../blocks/location/src/Location";
import Barcodesscanner from "../../blocks/barcodesscanner/src/Barcodesscanner";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Refundmanagement from "../../blocks/refundmanagement/src/Refundmanagement";
import PasscodeLock from "../../blocks/passcodelock/src/PasscodeLock";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Realtimeupdates2 from "../../blocks/realtimeupdates2/src/Realtimeupdates2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Shippingaddressvalidation2 from "../../blocks/shippingaddressvalidation2/src/Shippingaddressvalidation2";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Eventregistration from "../../blocks/eventregistration/src/Eventregistration";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Itemgrouper from "../../blocks/itemgrouper/src/Itemgrouper";
import Invoicebilling3 from "../../blocks/invoicebilling3/src/Invoicebilling3";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Cfcustomfeature12 from "../../blocks/cfcustomfeature12/src/Cfcustomfeature12";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Guestlogin2 from "../../blocks/guestlogin2/src/Guestlogin2";
import Share from "../../blocks/share/src/Share";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Contactsintegration from "../../blocks/contactsintegration/src/Contactsintegration";
import Taxcalculator from "../../blocks/taxcalculator/src/Taxcalculator";
import OfflineBrowsing from "../../blocks/offlinebrowsing/src/OfflineBrowsing";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import Sorting from "../../blocks/sorting/src/Sorting";
import Callrecording from "../../blocks/callrecording/src/Callrecording";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Deliveryestimator from "../../blocks/deliveryestimator/src/Deliveryestimator";
import Search from "../../blocks/search/src/Search";
import Cfcustomfeature62 from "../../blocks/cfcustomfeature62/src/Cfcustomfeature62";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Cfcustomfeature52 from "../../blocks/cfcustomfeature52/src/Cfcustomfeature52";
import Videos from "../../blocks/videos/src/Videos";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import StripeIntegration from "../../blocks/stripepayments/src/StripeIntegration";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Cfcustomfeature132 from "../../blocks/cfcustomfeature132/src/Cfcustomfeature132";
import Inventorymanagement2 from "../../blocks/inventorymanagement2/src/Inventorymanagement2";
import Searchhistory from "../../blocks/searchhistory/src/Searchhistory";
import Barcodesettings from "../../blocks/barcodesettings/src/Barcodesettings";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import GoogleCalendarSync from "../../blocks/googlecalendarsync/src/GoogleCalendarSync";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Cfcustomfeature142 from "../../blocks/cfcustomfeature142/src/Cfcustomfeature142";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Crm3rdpartyintegration from "../../blocks/crm3rdpartyintegration/src/Crm3rdpartyintegration";
import Cfcustomfeature42 from "../../blocks/cfcustomfeature42/src/Cfcustomfeature42";
import Analytics from "../../blocks/analytics/src/Analytics";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Cfcustomfeature22 from "../../blocks/cfcustomfeature22/src/Cfcustomfeature22";
import Customadvertisements from "../../blocks/customadvertisements/src/Customadvertisements";
import Loyaltysystem from "../../blocks/loyaltysystem/src/Loyaltysystem";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfcustomfeature32 from "../../blocks/cfcustomfeature32/src/Cfcustomfeature32";
import Cfcustomfeature73 from "../../blocks/cfcustomfeature73/src/Cfcustomfeature73";
import Cfcustomfeature152 from "../../blocks/cfcustomfeature152/src/Cfcustomfeature152";
import Maps from "../../blocks/maps/src/Maps";



const routeMap = {
Cfcustomfeature:{
 component:Cfcustomfeature,
path:"/Cfcustomfeature"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
ThemeBlock:{
 component:ThemeBlock,
path:"/ThemeBlock"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Cfcustomfeature82:{
 component:Cfcustomfeature82,
path:"/Cfcustomfeature82"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Applepayintegration2:{
 component:Applepayintegration2,
path:"/Applepayintegration2"},
Cfcustomfeature92:{
 component:Cfcustomfeature92,
path:"/Cfcustomfeature92"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
Pricingengine2:{
 component:Pricingengine2,
path:"/Pricingengine2"},
Cfcustomfeature122:{
 component:Cfcustomfeature122,
path:"/Cfcustomfeature122"},
Googleadsenseintegration:{
 component:Googleadsenseintegration,
path:"/Googleadsenseintegration"},
Cfcustomfeature43:{
 component:Cfcustomfeature43,
path:"/Cfcustomfeature43"},
Cfcustomfeature112:{
 component:Cfcustomfeature112,
path:"/Cfcustomfeature112"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Itemavailability:{
 component:Itemavailability,
path:"/Itemavailability"},
Savedcards:{
 component:Savedcards,
path:"/Savedcards"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cfcustomfeature72:{
 component:Cfcustomfeature72,
path:"/Cfcustomfeature72"},
Storelocator2:{
 component:Storelocator2,
path:"/Storelocator2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Cfcustomfeature102:{
 component:Cfcustomfeature102,
path:"/Cfcustomfeature102"},
Location:{
 component:Location,
path:"/Location"},
Barcodesscanner:{
 component:Barcodesscanner,
path:"/Barcodesscanner"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Refundmanagement:{
 component:Refundmanagement,
path:"/Refundmanagement"},
PasscodeLock:{
 component:PasscodeLock,
path:"/PasscodeLock"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Realtimeupdates2:{
 component:Realtimeupdates2,
path:"/Realtimeupdates2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Shippingaddressvalidation2:{
 component:Shippingaddressvalidation2,
path:"/Shippingaddressvalidation2"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Eventregistration:{
 component:Eventregistration,
path:"/Eventregistration"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Itemgrouper:{
 component:Itemgrouper,
path:"/Itemgrouper"},
Invoicebilling3:{
 component:Invoicebilling3,
path:"/Invoicebilling3"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Cfcustomfeature12:{
 component:Cfcustomfeature12,
path:"/Cfcustomfeature12"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Guestlogin2:{
 component:Guestlogin2,
path:"/Guestlogin2"},
Share:{
 component:Share,
path:"/Share"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Contactsintegration:{
 component:Contactsintegration,
path:"/Contactsintegration"},
Taxcalculator:{
 component:Taxcalculator,
path:"/Taxcalculator"},
OfflineBrowsing:{
 component:OfflineBrowsing,
path:"/OfflineBrowsing"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Callrecording:{
 component:Callrecording,
path:"/Callrecording"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Deliveryestimator:{
 component:Deliveryestimator,
path:"/Deliveryestimator"},
Search:{
 component:Search,
path:"/Search"},
Cfcustomfeature62:{
 component:Cfcustomfeature62,
path:"/Cfcustomfeature62"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Cfcustomfeature52:{
 component:Cfcustomfeature52,
path:"/Cfcustomfeature52"},
Videos:{
 component:Videos,
path:"/Videos"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Chat:{
 component:Chat,
path:"/Chat"},
ChatView:{
 component:ChatView,
path:"/ChatView"},
GroupVideoCall:{
 component:GroupVideoCall,
path:"/GroupVideoCall"},
Couponcodegenerator:{
 component:Couponcodegenerator,
path:"/Couponcodegenerator"},
StripeIntegration:{
 component:StripeIntegration,
path:"/StripeIntegration"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Cfcustomfeature132:{
 component:Cfcustomfeature132,
path:"/Cfcustomfeature132"},
Inventorymanagement2:{
 component:Inventorymanagement2,
path:"/Inventorymanagement2"},
Searchhistory:{
 component:Searchhistory,
path:"/Searchhistory"},
Barcodesettings:{
 component:Barcodesettings,
path:"/Barcodesettings"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
GoogleCalendarSync:{
 component:GoogleCalendarSync,
path:"/GoogleCalendarSync"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Cfcustomfeature142:{
 component:Cfcustomfeature142,
path:"/Cfcustomfeature142"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Crm3rdpartyintegration:{
 component:Crm3rdpartyintegration,
path:"/Crm3rdpartyintegration"},
Cfcustomfeature42:{
 component:Cfcustomfeature42,
path:"/Cfcustomfeature42"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Cfcustomfeature22:{
 component:Cfcustomfeature22,
path:"/Cfcustomfeature22"},
Customadvertisements:{
 component:Customadvertisements,
path:"/Customadvertisements"},
Loyaltysystem:{
 component:Loyaltysystem,
path:"/Loyaltysystem"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cfcustomfeature32:{
 component:Cfcustomfeature32,
path:"/Cfcustomfeature32"},
Cfcustomfeature73:{
 component:Cfcustomfeature73,
path:"/Cfcustomfeature73"},
Cfcustomfeature152:{
 component:Cfcustomfeature152,
path:"/Cfcustomfeature152"},
Maps:{
 component:Maps,
path:"/Maps"},

  Home: {
component:Filteritems,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
